<template>
  <div class="bg-default">
    <header class="header">
      <div class="header-left">
        <a href="#">
          <img
            class="header-settings-icon"
            src="img/settings-icon.png"
            alt="Settings"
          />
        </a>
      </div>
      <div class="header-center"></div>
      <div class="header-right">
        <a @click="exit()">
          <img
            class="header-settings-icon"
            src="img/exit-icon.png"
            alt="Exit"
          />
        </a>
      </div>
    </header>
    <main class="main">
      <div class="container">
        <div class="card card-800 card-centerred">
          <div class="card-title mb-48">
            Kedves<br />
            {{nickname}}
          </div>
          <div class="card-link card-link-blue mb-64">
            <a @click="checkPerson()">Nem én vagyok {{nickname}} mit tegyek?</a>
          </div>
          <div class="card-subtitle mb-64">
            Vedd fel a fülhallgatót, és állítsd<br />
            be a hangerőt!
          </div>
          <div class="volume mb-32">
            <div class="volume-down">
              <div class="volume-down-icon">
                <img
                  id="vol-down-icon"
                  src="img/volume-min-icon.png"
                  alt="Volume down"
                />
              </div>
            </div>
            <div class="volume-bar">
              <div id="volume-bar-line" class="volume-bar-line">
                <div
                  class="volume-bar-line-fill"
                  :style="{ width: volumeWidth + '%' }"
                ></div>
              </div>
              <div
                @touchstart="setVolume"
                class="volume-bar-slider"
                :style="{ left: volumeWidth + '%' }"
              ></div>
            </div>
            <div class="volume-up">
              <div class="volume-up-icon">
                <img
                  id="vol-up-icon"
                  src="img/volume-max-icon.png"
                  alt="Volume up"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <footer class="footer">
      <div class="container">
        <div class="footer-button-only">
          <a @click="goNext()" class="button button-blue">Készen állok</a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters(["groupId", "stopGameTurnId", "actualGameTurnId", "nickname"]),
  },
  methods: {
    goNext() {
      this.$store.commit("setVolume", this.volumeWidth);
      if (!this.groupId) {
        this.$store.dispatch("storeGameplayEvent", {
          round: 0,
          event: "video_1",
        });
        return this.$router.push("waitingcounter");
      }

      this.$store.commit("setStatus", "deny");
      if (this.stopGameTurnId === this.actualGameTurnId) return this.$router.push("group-start");
      this.$store.dispatch("storeGameplayEvent", {
        round: 0,
        event: "video_1",
      });
      return this.$router.push("waitingcounter");
    },
    checkPerson() {
      this.$router.push("personCheck");
    },
    setVolumeOnMouseMove(e) {
      if (this.drag) {
        // // console.log("clientY",e.clientY)
        /* let div = document.getElementById("volume-bar");
        let bounds = div.getBoundingClientRect();
        //// console.log(divY);
        // console.log("bounds", bounds);
        var allWidth = bounds.left + bounds.width;
        var y = e.x / allWidth;
        // console.log("x",y); */
        //var y = e.clientX - bounds.right;
        //// console.log("Y",y);
        //this.updateBar(y);
      }
    },
    updateBar(x) {
      //  // console.log(adat)
      //get volume bar
      var offsets = document
        .querySelector(".volume-bar-line")
        .getBoundingClientRect();
      var left = offsets.left;
      let volume = document.querySelector(".volume-bar-line");
      var position = x - left;
      var percentage = Math.round((100 * position) / volume.clientWidth);
      if (percentage > 100) return;
      if (percentage < 0) return;
      this.volumeWidth = percentage;
      /* if (y < 0) {
        this.volume = 100;
      } else if (y > this.volumeWidth) {
        this.volume = 0;
      } else {
        this.volume = Math.floor((((y - this.volumeWidth) * -1) / this.volumeWidth) * 100);
      }
      this.volumeWidth = this.volume; */

      //Set Global Volume
    },
    setVolume(e) {
      // console.log("ASD");
      this.drag = true;
      //this.setVolumeOnMouseMove(e);
    },
    mouseUp() {
      this.drag = false;
    },
  },
  data() {
    return {
      volumeWidth: 50,
      drag: false,
      volume: 50,
    };
  },
  mounted() {},
  created: function () {
    var self = this;
    window.addEventListener("touchmove", function (ev) {
      if (self.drag) {
        self.updateBar(ev.touches[0].clientX);
      }
    });

    window.addEventListener("touchend", function (ev) {
      self.drag = false;
    });
  },
  destroyed: function () {
    var self = this;
    window.removeEventListener("mousemove", function (ev) {
      if (self.drag) {
        self.updateBar(ev.clientX);
      }
    });

    window.addEventListener("touchend", function (ev) {
      self.drag = false;
    });
  },
};
</script>

<style>
</style>
